.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 35px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 99;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.error {
  color: red;
}

.error .css-yk16xz-control {
  border-color: red;
}

.error .css-1wa3eu0-placeholder {
  color: red;
}

.error label {
  color: red;
}

.ck-editor__editable_inline.ck-blurred {
  outline: 1px solid #ddd;
  outline-style: dotted;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.ck-editor__editable_inline.discussion-editor {
  min-height: 50px;
}

.ReactModalPortal {
  z-index: 999999 !important;
}

.ReactModal__Overlay--after-open {
  z-index: 999999 !important;
}

.MuiSnackbar-root {
  z-index: 9999999 !important;
}

.MuiCardHeader-root {
  padding: 10px;
}

.MuiCardContent-root {
  padding: 10px;
}

.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}

.swal2-container {
  z-index: 999999999!important;
}